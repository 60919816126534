.header {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 15px;
  background-color: #f5f5f5;
  background-color: #fff;
  /* box-shadow: 0 0 10px #888; */
}
.avatar {
  margin-left: auto;
}